<template>
    <div class="w-full relative h-screen flex flex-col overflow-hidden panel-layout">
        <div class="w-full absolute top-0 left-0 z-40 border-b-2 border-light">
            <v-header />
        </div>

        <v-sidebar />

        <div class="flex h-screen pt-16 divide-x-0 lg:divide-x-2 divide-light">
            <!-- Static sidebar for desktop -->
            <div class="hidden lg:flex lg:flex-shrink-0 z-30">
                <div class="flex flex-col w-24 z-30">
                    <div class="flex flex-col h-0 flex-1 overflow-y-auto bg-default">
                        <div class="flex-1 flex flex-col justify-between">
                            <v-vertical-menu class="relative flex flex-col items-center justify-center gap-4 py-4">
                                <a v-tooltip="{ ...gameIconTooltipOptions, content: 'All Games' }" href="#" :class="(!selectedGame || selectedGame === null) ? 'bg-black' : 'hover:bg-black'" class="flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg" @click="selectGame(null)">
                                    <img :src="require('@/assets/app-icon.svg')" class="mx-auto w-7 h-7">
                                </a>

                                <template v-for="game in filteredGames">
                                    <a
                                        :key="'game_icon_' + game.game_id"
                                        v-tooltip="{ ...gameIconTooltipOptions, content: game.name }"
                                        href="#"
                                        :class="(selectedGame === String(game.game_id)) ? 'bg-black' : 'hover:bg-black'"
                                        class=" flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg"
                                        @click="selectGame(game.game_id)"
                                    >
                                        <img :src="game.logo_dark_url" class="mx-auto w-7 h-7">
                                    </a>
                                </template>
                            </v-vertical-menu>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-1 min-w-0 flex flex-col overflow-hidden">
                <main class="flex-1 flex overflow-hidden">
                    <div class="w-full h-full flex flex-col max-h-screen divide-y-2 lg:divide-y-0 divide-default">
                        <div class="bg-default block lg:hidden w-full p-default overflow-x-auto overflow-y-hidden">
                            <div class="flex flex-row items-center gap-4">
                                <a href="#" :class="(!selectedGame || selectedGame === null) ? 'bg-black' : 'hover:bg-black'" class="flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-lg" @click="selectGame(null)">
                                    <img :src="require('@/assets/app-icon.svg')" class="mx-auto w-7 h-7">
                                </a>

                                <template v-for="game in games">
                                    <a :key="'game_icon_' + game.game_id" href="#" :class="(selectedGame === String(game.game_id)) ? 'bg-black' : 'hover:bg-black'" class="flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-lg" @click="selectGame(game.game_id)">
                                        <img :src="game.logo_dark_url" class="mx-auto w-7 h-7">
                                    </a>
                                </template>
                            </div>
                        </div>

                        <v-application-alerts />
                        <router-view />
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
// import Breadcrumbs from "./fragments/breadcrumbs.vue";
import GameService from "@/services/modules/game-service";
import Header from "./fragments/header.vue";
import Sidebar from "./fragments/sidebar.vue";

export default {
    components: {
        // "v-breadcrumbs": Breadcrumbs,
        "v-header": Header,
        "v-sidebar": Sidebar,
    },
    data() {
        return {
            gameService: GameService,
            games: [],
            gameIconTooltipOptions: {
                placement: "right",
                delay: { show: 50, hide: 50 },
                classes: "ml-20",
            },
        };
    },
    computed: {
        /**
         * Get the filtered games.
         */
        filteredGames() {
            return this.games.filter((game) => (!game.parent_game_id));
        },

        /**
         * @todo Write a description.
         */
        selectedGame() {
            const result = this.$store.getters["appModule/getSettings"].selectedGame;

            if (result === "null") {
                return null;
            }

            return result;
        },

        /**
         * @todo Write a description.
         */
        isRouteMe() {
            return this.$route.name.includes("me.profile", 0);
        },

        /**
         * @todo Write a description.
         */
        hasImpersonationToken() {
            return localStorage.getItem("impersonation_token");
        },
    },
    created() {
        // this.toggleLoading();

        // this.$me.fetchUser()
        //     .catch((error) => {
        //         console.error("error", error);
        //     })
        //     .finally(() => {
        //         setTimeout(() => {
        //             this.toggleLoading();
        //         }, 3500);
        //     });

        this.gameService.index({})
            .then((response) => {
                this.games = response.data.data;
            });
    },
    methods: {
        stopImpersonation() {
            localStorage.removeItem("impersonation_token");

            window.location.href = this.$router.resolve({ name: "users" }).href;
        },

        /**
         * @todo Write a description.
         */
        selectGame(gameId) {
            if ((this.selectedGame === null) && (gameId === null)) {
                return;
            }

            if (this.selectedGame === gameId) {
                this.$store.commit("appModule/setSetting", { key: "selectedGame", value: null });
                return;
            }

            this.$store.commit("appModule/setSetting", { key: "selectedGame", value: String(gameId) });
        },
    },
};
</script>

<style lang="scss">
    .panel-layout {
        background-image: url('./../../assets/app-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }
</style>
