<template>
    <div class="flex">
        <!-- Mobile View -->
        <div class="flex lg:hidden">
            <transition
                enter-active-class="transition-opacity ease-linear duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity ease-linear duration-300"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div v-if="sidebarOpen" class="fixed inset-0 flex z-50" @click="sidebarOpen = false">
                    <div class="absolute inset-0 bg-black opacity-50" />
                </div>
            </transition>

            <transition
                enter-active-class="transition ease-in-out duration-300 transform"
                enter-class="-translate-x-full"
                enter-to-class="translate-x-0"
                leave-active-class="transition ease-in-out duration-300 transform"
                leave-class="translate-x-0"
                leave-to-class="-translate-x-full"
            >
                <div v-if="sidebarOpen" class="fixed flex h-full z-50">
                    <v-menu-sidebar />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sidebarOpen: false,
        };
    },
    created() {
        this.$eventBus.$on("toggleSidebar", () => {
            this.toggleSidebar();
        });
        document.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                this.sidebarOpen = false;
            }
        });
    },
    methods: {
        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen;
        },
    },
};
</script>
