var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"bg-default"},[_c('div',{staticClass:"px-default"},[_c('div',{staticClass:"relative h-16 flex items-center lg:justify-between"},[_c('v-section-group',{staticClass:"flex items-center"},[_c('div',{staticClass:"hidden lg:flex items-center"},[_c('div',{staticClass:"flex-shrink-0"},[_c('router-link',{attrs:{"to":{ name: 'panel.dashboard' },"custom":""}},[_c('img',{staticClass:"h-4 w-auto",attrs:{"src":require('@/assets/app-logo-light.svg')}})])],1)]),_c('div',{staticClass:"flex lg:hidden space-x-default"},[_c('v-button',{attrs:{"color":"dark","size":"icon"},on:{"click":function($event){$event.preventDefault();return _vm.$eventBus.$emit('toggleSidebar')}}},[_c('svg',{staticClass:"block h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M4 6h16M4 12h16M4 18h16"}})]),_c('svg',{staticClass:"hidden h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])]),_c('div',{staticClass:"inline-flex items-center justify-center"},[_c('img',{staticClass:"h-4 w-auto",attrs:{"src":require('@/assets/app-logo-light.svg')}})])],1),_c('div',{staticClass:"hidden lg:block px-default"},[_c('div',{staticClass:"flex items-center space-x-default"},[_vm._l((_vm.menu),function(ref,menuItemIndex){
var label = ref.label;
var route = ref.route;
var visible = ref.visible; if ( visible === void 0 ) visible = true;
return [(visible)?_c('router-link',{key:'menuItem_' + menuItemIndex,attrs:{"to":route,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('v-button',{attrs:{"color":"transparent","size":"sm","href":href,"active":isActive},on:{"click":navigate}},[_vm._v(" "+_vm._s(label)+" ")])]}}],null,true)}):_vm._e()]})],2)])]),_c('div',{staticClass:"hidden lg:block"},[_c('div',{staticClass:"flex items-center"},[(_vm.hasImpersonationToken)?[_c('v-button',{attrs:{"size":"sm","color":"danger"},on:{"click":function($event){return _vm.stopImpersonation()}}},[_vm._v(" Stop Impersonation ")])]:_vm._e(),(_vm.$me.getUser())?[_c('v-button',{attrs:{"size":"sm","color":"transparent","to":{ name: 'me.profile' },"active":_vm.isRouteMe}},[_vm._v(" "+_vm._s(_vm.$me.getUser().name)+" ")])]:_vm._e(),[_c('v-button',{attrs:{"size":"sm","color":"transparent","to":{ name: 'auth.logout' }}},[_c('i',{staticClass:"fa fa-sign-out-alt"})])]],2)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }