<template>
    <nav class="bg-default">
        <div class="px-default">
            <div class="relative h-16 flex items-center lg:justify-between">
                <v-section-group class="flex items-center">
                    <div class="hidden lg:flex items-center">
                        <div class="flex-shrink-0">
                            <router-link :to="{ name: 'panel.dashboard' }" custom>
                                <img class="h-4 w-auto" :src="require('@/assets/app-logo-light.svg')">
                            </router-link>
                        </div>
                    </div>

                    <div class="flex lg:hidden space-x-default">
                        <v-button color="dark" size="icon" @click.prevent="$eventBus.$emit('toggleSidebar')">
                            <svg class="block h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>

                            <svg class="hidden h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </v-button>

                        <div class="inline-flex items-center justify-center">
                            <img class="h-4 w-auto" :src="require('@/assets/app-logo-light.svg')">
                        </div>
                    </div>

                    <!-- Links -->
                    <div class="hidden lg:block px-default">
                        <div class="flex items-center space-x-default">
                            <template v-for="({ label, route, visible = true }, menuItemIndex) in menu">
                                <router-link v-if="visible" :key="'menuItem_' + menuItemIndex" v-slot="{ isActive, href, navigate }" :to="route" custom>
                                    <v-button color="transparent" size="sm" :href="href" :active="isActive" @click="navigate">
                                        {{ label }}
                                    </v-button>
                                </router-link>
                            </template>
                        </div>
                    </div>
                </v-section-group>

                <!-- Actions -->
                <div class="hidden lg:block">
                    <div class="flex items-center">
                        <template v-if="hasImpersonationToken">
                            <v-button size="sm" color="danger" @click="stopImpersonation()">
                                Stop Impersonation
                            </v-button>
                        </template>

                        <template v-if="$me.getUser()">
                            <v-button size="sm" color="transparent" :to="{ name: 'me.profile' }" :active="isRouteMe">
                                {{ $me.getUser().name }}
                            </v-button>
                        </template>

                        <template>
                            <v-button size="sm" color="transparent" :to="{ name: 'auth.logout' }">
                                <i class="fa fa-sign-out-alt" />
                            </v-button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    computed: {
        isRouteMe() {
            return this.$route.name.includes("me.", 0);
        },
        menu() {
            return [
                {
                    label: "Dashboard",
                    route: { name: "panel.dashboard" },
                    icon: "fa fa-desktop",
                },
                {
                    label: "Orders",
                    route: { name: "orders" },
                    icon: "fa fa-boxes",
                    visible: this.$me.hasPermission("orders.read"),
                },
                {
                    label: "Jobs",
                    route: { name: "jobs" },
                    icon: "fas fa-suitcase",
                    visible: this.$me.hasPermission("order-lines.read"),
                },
                {
                    label: "Payouts",
                    route: { name: "payouts" },
                    icon: "fas fa-file-invoice",
                    visible: this.$me.hasPermission("payouts.read"),
                },
                {
                    label: "Conversations",
                    route: { name: "conversations" },
                    icon: "fas fa-comments",
                    visible: this.$me.hasPermission("conversations.read"),
                },
                {
                    label: "Users",
                    route: { name: "users" },
                    icon: "fas fa-users",
                    visible: this.$me.hasPermission("users.read"),
                },
                {
                    label: "Roles",
                    route: { name: "roles" },
                    icon: "fas fa-user-secret",
                    visible: this.$me.hasPermission("roles.read"),
                },
                {
                    label: "Reports",
                    route: { name: "reports" },
                    icon: "fa-solid fa-file-lines",
                    visible: this.$me.hasPermission("reports.read"),
                },
            ];
        },
        hasImpersonationToken() {
            return localStorage.getItem("impersonation_token");
        },
    },
    methods: {
        stopImpersonation() {
            localStorage.removeItem("impersonation_token");

            window.location.href = this.$router.resolve({ name: "users" }).href;
        },
    },
};
</script>
